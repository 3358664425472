
import { Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElMessageBox } from "element-plus";

interface Response {
  status: boolean;
  has_confirmation_url: boolean;
  message: string;
  confirmation_url: string;
}

export default class BraintreeAPI extends Vue {
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  existShopifyConnect = false;
  net: any;
  connectHubSP: any;
  isCollapse = false;
  isSuperAdmin = false;
  showMassSync = false;
  icon = "el-icon-caret-left";
  targetConnection = "";
  listConnectSPTarget: any = [];
  listExpiryShopify: any = [];
  listConnectChangeBilling: any = [];
  now = new Date();
  hubIsShopify = false;
  accessToken: any;
  shopify_shopName: any;
  newConnect: any;
  async created() {
    await this.account();
  }
  async account() {
    await this.$store.dispatch("setLoading", true, { root: true });
    await axios
      .post("user/account/detail",{}, {
        headers: this.headers,
      })
      .then((response) => {
        this.net = response;
        this.net.data.connectionData.forEach((element: any) => {
          element.connections.forEach((connection: any) => {
            connection.expiryDate = element.expiryDate;
            connection.isActiveShopify = element.isActiveShopify;
            connection.charge_id = element.charge_id;
            if (connection.type == "shopify" && connection.from) {
              this.hubIsShopify = true;
              this.accessToken = connection?.connection?.tokenObject?.accessToken;
              this.shopify_shopName = connection?.connection?.shopify_shopName;
              this.connectHubSP = element;
              return;
            }
            if (
              !this.hubIsShopify &&
              connection.type == "shopify" &&
              connection.deletedDate == undefined
            ) {
              this.listConnectSPTarget.push(connection);
            }
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });

    if (this.listExpiryShopify.length > 0) {
      this.listExpiryShopify.forEach((a: any) => {
        a.connections.forEach((connection: any) => {
          if (connection.type == "shopify" && !connection.from) {
            this.listConnectSPTarget.push({
              accessToken: connection?.connection?.tokenObject?.accessToken,
              shopify_shopName: connection?.connection?.shopify_shopName,
              package_id: a._id,
              package_price: a.price,
              no_trial_days: true,
            });
          }
        });
      });
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  async doRenew(data: any) {
    let params = {
      accessToken: data.connection.tokenObject.accessToken,
      shopify_shopName: data.connection.shopify_shopName,
      package_id: this.hubIsShopify ? undefined : data._id,
      package_price: this.hubIsShopify ? undefined : data.price,
      no_trial_days: true,
    };    
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: Response = await axios.get(
      "user/connect/shopify-comfirm-subscription",
      {
        headers: this.headers,
        params: JSON.stringify(params),
      }
    );
    if (res.status && res.has_confirmation_url) {
      window.location.href = res.confirmation_url;
    } else {
      this.$message.warning(res.message);
      await this.$store.dispatch("setLoading", false, {
        root: true,
      });
    }
  }
  doCancel(data: any) {
    ElMessageBox.confirm(
      "Do you want to cancle this subscription?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    ).then(async () => {
      await this.cancleSubscription(data);
      this.$store.dispatch('reRender')
    });
  }
  async cancleSubscription(data: any) {
    if (data.charge_id == undefined) {
      return this.$message.warning("Charge id is not found!!!");
    }
    let body = {
      charge_id: data.charge_id,
      accessToken: data.connection.tokenObject.accessToken,
      shopify_shopName: data.connection.shopify_shopName,
    };
    await this.$store.dispatch("setLoading", true, {
      root: true,
    });
    let res: Response = await axios.post(
      "user/connect/cancel-subscription",
      body,
      {
        headers: this.headers,
      }
    );
    if (res.status) {
      this.$message.success(res.message);
    } else {
      this.$message.warning(res.message);
    }
    await this.$store.dispatch("setLoading", false, {
      root: true,
    });
    this.listConnectSPTarget = [];
    await this.account();
  }
}
